import React, { Component } from "react"
import PropTypes from "prop-types"

class Content extends Component {
    render() {

        var flexStyle = {};
        if (this.props.flexCenter == true) {
            flexStyle = {
                marginTop: 'unset',
                marginBottom: 'unset',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)'
            };
        } else {
            flexStyle = {
                position: 'initial',
                top: 'unset',
                transform: 'unset',
                marginTop: '40px',
                marginBottom: '40px'
            };
        }

        return (
            <div className="Content">
                <div className="Content-wrapper" style={flexStyle}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

Content.propTypes = {
    flexStyle: PropTypes.bool.isRequired
}

export default Content